import UpperCaseText from "./UpperCaseText";

const IS_CANADA_MARKET = (market) => {
  return UpperCaseText(market) === "CAN";
};

const IS_SPAIN_MARKET = (market) => {
  return UpperCaseText(market) === "ESP";
};

const IS_BELGIUM_MARKET = (market) => {
  return UpperCaseText(market) === "BEL";
};

const IS_SWISS_MARKET = (market) => {
  return UpperCaseText(market) === "CHE";
};

const IS_USA_MARKET = (market) => {
  return UpperCaseText(market) === "USA";
};

const IS_NA_MARKET = (market) => {
  return UpperCaseText(market) === "USA" || UpperCaseText(market) === "CAN";
};

const IS_SA_MARKET = (market) => {
  return "ARG" === UpperCaseText(market) || "BRA" === UpperCaseText(market);
};

const IS_AUS_MARKET = (market) => {
  return "AUS" === UpperCaseText(market);
};
const IS_ZAF_MARKET = (market) => {
  return "ZAF" === UpperCaseText(market);
};

const IS_VNM_MARKET = (market) => {
  return "VNM" === UpperCaseText(market);
};

const IS_THA_MARKET = (market) => {
  return "THA" === UpperCaseText(market);
};

const IS_CRC_USER = (user) => {
  return UpperCaseText(user) === "CRC";
};

const IS_FORD_BRAND = (brand) => {
  return "F" === brand;
};

const HAS_VALUE = (input) => {
  return input !== null && input !== undefined;
};

const NULL_OR_UNDEFINED = (input) => {
  return input === null || input === undefined;
};

const NOTNULL_OR_NOTUNDEFINED = (input) => {
  return !NULL_OR_UNDEFINED(input);
};

const FORMAT_COMMON_ID = (commonDealerId) => {
  return commonDealerId.split("|").join("%7C");
};

const ONLINE_TRADE_IN_MARKETS = ["GBR", "FRA", "NLD"];

const OFFLINE_TRADE_IN_MARKETS = ["DEU", "NOR", "CHE"];

const BASE_URL =
  typeof window !== "undefined" && window.EMP ? window.EMP.BASE_URL : "";

const RESERVATION_BASE_URL =
  typeof window !== "undefined" && window.RESERVATION_SERVICE
    ? window.RESERVATION_SERVICE.BASE_URL
    : "";

const RESERVATION_ACTION_URL =
  typeof window !== "undefined" && window.RESERVATION_SERVICE
    ? window.RESERVATION_SERVICE.ACTION_URL
    : "";

const SHARED_SERVICE_URL =
  typeof window !== "undefined" && window.EMP
    ? window.EMP.SHARED_SERVICE_URL
    : "";

const ENV = typeof window !== "undefined" && window.EMP ? window.EMP.ENV : "";

const BASE_REPORTING_URL =
  typeof window !== "undefined" && window.REPORTING_SERVICE
    ? window.REPORTING_SERVICE.BASE_URL
    : "";

const AUTHORIZE_BASE_URL =
  typeof window !== "undefined" && window.AUTH_SERVICE
    ? window.AUTH_SERVICE.BASE_AUTHORIZE_URL
    : "";

const TRANSLATION_BASE_URL =
  typeof window !== "undefined" && window.EMP ? window.EMP.TRANSLATION_URL : "";

const IMG_SA_URL =
  typeof window !== "undefined" && window.IMG ? window.IMG.URL : "";

const GET_DEALER_NAME = (dealer, market) => {
  if (
    IS_VNM_MARKET(market) &&
    NOTNULL_OR_NOTUNDEFINED(dealer.localLocationName)
  ) {
    return dealer.localLocationName;
  } else if (NOTNULL_OR_NOTUNDEFINED(dealer.locationName)) {
    return dealer.locationName;
  } else {
    return dealer.name;
  }
};

export {
  IS_FORD_BRAND,
  IS_CANADA_MARKET,
  IS_USA_MARKET,
  BASE_URL,
  SHARED_SERVICE_URL,
  RESERVATION_BASE_URL,
  RESERVATION_ACTION_URL,
  AUTHORIZE_BASE_URL,
  BASE_REPORTING_URL,
  TRANSLATION_BASE_URL,
  ONLINE_TRADE_IN_MARKETS,
  OFFLINE_TRADE_IN_MARKETS,
  HAS_VALUE,
  NOTNULL_OR_NOTUNDEFINED,
  NULL_OR_UNDEFINED,
  ENV,
  IS_SPAIN_MARKET,
  IS_SWISS_MARKET,
  IS_BELGIUM_MARKET,
  FORMAT_COMMON_ID,
  IS_NA_MARKET,
  IS_SA_MARKET,
  IS_CRC_USER,
  IMG_SA_URL,
  IS_AUS_MARKET,
  IS_ZAF_MARKET,
  IS_THA_MARKET,
  IS_VNM_MARKET,
  GET_DEALER_NAME,
};
